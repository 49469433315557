<template>
  <v-container>
    <v-card class="mb-6">
      <v-card-title>Websocket to receive Data</v-card-title>
      <v-card-text>Populates and builds form below</v-card-text>
      <v-row>
        <v-card-text>
          <v-card>
            <vue-webrtc
              ref="webrtc"
              width="100%"
              :room-id="roomId"
              socket-u-r-l="https://pharmaguide.ngrok.io"
              @joined-room="logEvent"
              @left-room="logEvent"
              @opened-room="logEvent"
              @share-started="logEvent"
              @share-stopped="logEvent"
              @error="onError"
            />
          </v-card>
        </v-card-text>
        <v-col class="col-md-12 my-3">
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onJoin"
          >
            Join
          </v-btn>
          <v-btn
            type="button"
            class="btn btn-primary"
            @click="onLeave"
          >
            Leave
          </v-btn>
          <!-- <button type="button" class="btn btn-primary" @click="onCapture">Capture Photo</button>
            <button type="button" class="btn btn-primary" @click="onShareScreen">Share Screen</button> -->
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <FormulateForm
        v-model="values"
        class="formulate-input"
        :schema="schema"
      />
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { WebRTC } from 'vue-webrtc'

Vue.component(WebRTC.name, WebRTC)

export default {
  name: 'App',
  components: {},
  data() {
    return {
      img: null,
      roomId: '',
      values: {},
      schema: [
        {
          type: 'password',
          name: 'password',
          label: 'Enter a new password',
          validation: 'required',
        },
        {
          type: 'password',
          name: 'password_confirm',
          label: 'Confirm your password',
          validation: '^required|confirm:password',
          validationName: 'Password confirmation',
        },
        {
          type: 'submit',
          label: 'Change password',
        },
        {
          type: 'text',
          name: 'name',
          label: 'What is your name?',
          placeholder: 'Your name...',
          validation: 'required',
        },
        {
          type: 'text',
          name: 'address',
          label: 'What is your street address?',
          placeholder: 'Your address...',
          help: 'Where would you like your product shipped?',
          validation: 'required',
        },
        {
          type: 'radio',
          name: 'method',
          label: 'What shipping method would you like?',
          options: [
            {
              value: 'fedex_overnight',
              id: 'fedex_overnight',
              label: 'FedEx overnight',
            },
            {
              value: 'fedex_ground',
              id: 'fedex_ground',
              label: 'FedEx ground',
            },
            {
              value: 'usps',
              id: 'usps',
              label: 'US Postal Service',
            },
          ],
          value: 'fedex_ground',
          'validation-name': 'Shipping method',
          validation: 'required',
        },
        {
          name: 'submit',
          type: 'submit',
          label: 'Submit order',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  beforeMount() {
    this.roomId = this.$route.params.roomId
  },
  methods: {
    onCapture() {
      this.img = this.$refs.webrtc.capture()
    },
    onJoin() {
      this.$refs.webrtc.join()
    },
    onLeave() {
      this.$refs.webrtc.leave()
    },
    onShareScreen() {
      this.img = this.$refs.webrtc.shareScreen()
    },
    onError(error, stream) {
      console.log('On Error Event', error, stream)
    },
    logEvent(event) {
      console.log('Event : ', event)
    },
  },
}
</script>
